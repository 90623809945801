export default [
  // USER PROFILE
  {
    name: "UserProfile",
    path: "/user-profile",
    component: () => import("@/views/UserProfile"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
      title: "Informações do usuário",
    },
  },

  // SETUP MENU
  {
    name: "Módulos",
    path: "/module",
    component: () => import("@/views/Module"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "Transações",
    path: "/transaction",
    component: () => import("@/views/Transaction"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "Classe de clientes",
    path: "/client-class",
    component: () => import("@/views/ClientClass"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "Clientes",
    path: "/client",
    component: () => import("@/views/Client"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "Módulos do cliente",
    path: "/client-module",
    component: () => import("@/views/ClientModule"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "AddressType",
    path: "/address-type",
    component: () => import("@/views/AddressType"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "CustomerType",
    path: "/customer-type",
    component: () => import("@/views/CustomerType"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "Tipo do evento",
    path: "/event-type",
    component: () => import("@/views/EventType"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "Tipo de dado",
    path: "/account-data-type",
    component: () => import("@/views/AccountDataType"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "Tipo de conta",
    path: "/account-type",
    component: () => import("@/views/AccountType"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "Tipo de propósito",
    path: "/purpose-type",
    component: () => import("@/views/PurposeType"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "Mensagem",
    path: "/message",
    component: () => import("@/views/Message"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "Tipo de mensagem",
    path: "/message-type",
    component: () => import("@/views/MessageType"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "Versão do fechamento contábil",
    path: "/accounting-closing-version",
    component: () => import("@/views/AccountingClosingVersion"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },

  // ADMIN MENU
  {
    name: "Usuários",
    path: "/users",
    component: () => import("@/views/User"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "Grupos",
    path: "/group",
    component: () => import("@/views/Group"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "user-group",
    path: "/user-group",
    component: () => import("@/views/UserGroup"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "Perfil de acesso",
    path: "/group-transaction",
    component: () => import("@/views/GroupTransaction"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "StatusType",
    path: "/status-type",
    component: () => import("@/views/StatusType"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "Status",
    path: "/status",
    component: () => import("@/views/Status"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "Usuários X Centros de custo",
    path: "/user-cost-center",
    component: () => import("@/views/UserCostCenter"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },

  // GROUP REPORTS MENU
  {
    name: "Acessos dos usuários",
    path: "/user-log-access",
    component: () => import("@/views/UserLogAccess"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "Log de atividades dos usuarios",
    path: "/user-log-activity",
    component: () => import("@/views/UserLogActivity"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },

  // INTEGRATION MENU
  {
    name: "Processar integrações do ERP ao ZNAP",
    path: "/erp-integration-run",
    component: () => import("@/views/ErpIntegrationRun"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "Consultar status das integrações do ERP ao ZNAP",
    path: "/erp-integration",
    component: () => import("@/views/ErpIntegration"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "Processar integrações do ZNAP ao ERP",
    path: "/to-erp-integration-run",
    component: () => import("@/views/ToErpIntegrationRun"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "Consultar status das integrações do ZNAP ao ERP",
    path: "/to-erp-integration",
    component: () => import("@/views/ToErpIntegration"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "Controle mensal das integrações",
    path: "/erp-integration-run-monthly-control",
    component: () => import("@/views/ErpIntegrationRunMonthlyControl"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },

  // SECURITY REPORTS MENU
  {
    name: "Validação entre bases",
    path: "/base-validation",
    component: () => import("@/views/BaseValidation"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "Validação de contas contábeis",
    path: "/chart-account-validation",
    component: () => import("@/views/ChartAccountValidation"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
];
