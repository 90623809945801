"use strict";

import en from "vuetify/es5/locale/en";

export default {
  ...en,
  export: "Export sheet",
  import: "Import sheet",
  searchLabel: "Search",
  reload: "Reload",
  massDeleteBtn: "Delete selected items",
  crudCreateBtn: "Create",
  crudDeleteBtn: "Delete",
  crudUpdateBtn: "Edit",
  editingTableSnack: "Editing table line",
  cancelTableSnack: "Edition canceled",
  saveTableSnack: "Edition saved",
  toastError: "Oh...!",
  toastSuccess: "Success!",
  countryCode: "Country code",
  deleteSure: "Are you sure that you want to delete this item?",
  client: {
    title: "Clients",
    dialog: {
      create: "Create client",
      delete: "Delete client",
      massDelete: "Are you sure that you want to delete these clients?",
      update: "Update client",
    },
  },
  module: {
    title: "Module",
    dialog: {
      create: "Create module",
      delete: "Delete module",
      massDelete: "Are you sure that you want to delete these modules?",
      update: "Update module",
    },
  },
  clientClass: {
    title: "Client class",
    dialog: {
      create: "Create client class",
      delete: "Delete client class",
      massDelete: "Are you sure you want to delete these client classes?",
      update: "Update client class",
    },
  },
  clientModule: {
    title: "Client module",
    dialog: {
      create: "Relate module and client",
      delete: "Delete relation",
      massDelete: "Are you sure you want to break these relationships?",
      update: "Update client and module relationship",
    },
  },
  group: {
    title: "User group",
    dialog: {
      create: "Create user group",
      delete: "Delete user group",
      massDelete: "Are you sure you want to delete these user groups?",
      update: "Update user group",
    },
  },
  transaction: {
    title: "Transaction",
    dialog: {
      create: "Create transaction",
      delete: "Delete transaction",
      massDelete: "Are you sure that you want to delete these transactions?",
      update: "Update transaction",
    },
  },
  transactionGroup: {
    title: "Access profile",
    dialog: {
      create: "Create access profile",
      delete: "Delete access profile",
      massDelete: "Are you sure that you want to delete these access profiles?",
      update: "Update access profile",
    },
  },
  user: {
    title: "User",
    dialog: {
      create: "Create user",
      delete: "Deactivate user",
      massDelete: "Are you sure that you want to deactivate these users?",
      update: "Update user",
    },
  },
  userGroup: {
    title: "User profile",
    dialog: {
      create: "Create user profile",
      delete: "Delete user profile",
      massDelete: "Are you sure that you want to delete these user profiles?",
      update: "Update user profile",
    },
  },
};
